import React, { useCallback, useEffect, useRef, useState } from 'react';
import { navigate, PageProps } from 'gatsby';

import Layout from 'components/_layout';
import { CarrinhoHolder, Holder, ValoresHolder } from 'styles/pages/carrinho';
import Button from 'components/Button';

import { toast } from 'react-toastify';
import { ICartDTO, emptyCart } from 'components/Cart';
import ItemCarrinho from 'components/ItemCarrinho';
import api from 'services/api';
import { v4 } from 'uuid';

interface IValues {
  avista: number;
  umx: number;
  tresx: number;
  seisx: number;
  dezx: number;
}

const Carrinho = (props: PageProps) => {
  const { location } = props;

  const buttonRef = useRef<HTMLButtonElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [orderId, setOrderId] = useState<string | undefined>(undefined);
  const [update, setUpdate] = useState<boolean>(false);
  const [carrinho, setCarrinho] = useState<ICartDTO | undefined>();
  const [value, setValue] = useState<IValues | undefined>();

  const loadValue = useCallback((carrinhoParsed: ICartDTO) => {
    let values: IValues = {
      avista: 0.0,
      umx: 0.0,
      tresx: 0.0,
      seisx: 0.0,
      dezx: 0.0,
    };

    carrinhoParsed.items.forEach(item => {
      const { livro, quantity: qtd } = item;
      const {
        descontoAvista,
        desconto1x,
        desconto3x,
        desconto6x,
        desconto10x,
      } = livro;
      const precoNumber = Number(livro.preco.replace('$', ''));

      const preços: any = {
        avista:
          descontoAvista && precoNumber - (precoNumber * descontoAvista) / 100,
        umx: desconto1x && precoNumber - (precoNumber * desconto1x) / 100,
        tresx: desconto3x && precoNumber - (precoNumber * desconto3x) / 100,
        seisx: desconto6x && precoNumber - (precoNumber * desconto6x) / 100,
        dezx: desconto10x && precoNumber - (precoNumber * desconto10x) / 100,
      };

      const { avista, umx, tresx, seisx, dezx } = preços;

      if (qtd !== 0) {
        values = {
          avista: avista * qtd + values.avista,
          umx: umx * qtd + values.umx,
          tresx: tresx * qtd + values.tresx,
          seisx: seisx * qtd + values.seisx,
          dezx: dezx * qtd + values.dezx,
        };
      }
    });

    setValue(values);
  }, []);

  const loadCarrinho = useCallback(() => {
    localStorage.setItem('@FGLivraria:cartSession', v4());
    const carrinhoStg = localStorage.getItem('@FGLivraria:cart');
    if (carrinhoStg) {
      const carrinhoParsed = JSON.parse(carrinhoStg);
      setCarrinho(carrinhoParsed);
      loadValue(carrinhoParsed);
    }
  }, [update]);

  const finalizarCompra = useCallback(() => {
    if (!localStorage.getItem('@FGLivraria:user')) {
      navigate('/minha-conta?redirect=321');
      return;
    }

    const cartBrute = localStorage.getItem('@FGLivraria:cart');

    if (!cartBrute) {
      return;
    }

    const cart: ICartDTO = JSON.parse(cartBrute);

    if (buttonRef.current) {
      buttonRef.current.style.cursor = 'not-allowed';
      buttonRef.current.disabled = true;
    }

    try {
      setLoading(true);
      api.defaults.headers.authorization = `Bearer ${localStorage.getItem(
        '@FGLivraria:token'
      )}`;
      api
        .post('/orders', {})
        .then(res => {
          const order_id = res.data.id;
          setOrderId(order_id);

          cart.items.forEach(item => {
            const item_data = {
              order_id,
              livro_id: item.livro.id,
              price: parseInt(item.livro.preco.replace('$', '')),
              quantity: item.quantity,
            };
            api
              .post('/order_item', item_data)
              .then(res_item => {
                if (buttonRef.current) {
                  buttonRef.current.onclick = () => true;
                }
                cart.orderId = order_id;
                localStorage.setItem(
                  '@FGLivraria:activeOrder',
                  JSON.stringify(cart)
                );
                localStorage.removeItem('@FGLivraria:cart');
              })
              .catch(err => console.error(`Erro: ${err}`));
          });

          navigate(`/finalizar-compra?order_id=${order_id}`);
        })
        .catch(err => {
          toast.error(
            '❌ Erro ao fazer seu pedido. Tente novamente mais tarde.',
            {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          if (buttonRef.current) {
            buttonRef.current.style.cursor = 'pointer';
            buttonRef.current.disabled = false;
          }
          console.error(`Erro: ${err}`);
        });
    } catch (err) {
      toast.error('❌ Erro ao fazer seu pedido. Tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if (buttonRef.current) {
        buttonRef.current.style.cursor = 'pointer';
        buttonRef.current.disabled = false;
      }
      console.error(`Erro: ${err}`);
    } finally {
      setLoading(false);
      setUpdate(!update);
    }
  }, [loading]);

  useEffect(() => {
    loadCarrinho();
  }, [loadCarrinho]);

  return (
    <Layout pathname={location.pathname}>
      <CarrinhoHolder>
        <h1>Carrinho</h1>
        {carrinho && carrinho.items.length !== 0 ? (
          <Holder>
            <ul>
              {carrinho &&
                carrinho.items &&
                carrinho.items.map((item, index) => (
                  <ItemCarrinho
                    update={update}
                    setUpdate={setUpdate}
                    key={item.livro.id}
                    item_id={item.id}
                    livro={item.livro}
                    serie_id={item.serie_id}
                  />
                ))}
            </ul>
            {value && value.avista !== 0.0 && (
              <>
                <ValoresHolder>
                  <strong>Total:</strong>
                  {value.avista !== 0.0 && (
                    <div className="avista">
                      <strong>
                        R$ {value.avista.toFixed(2).replace('.', ',')}
                      </strong>
                      <span>à vista </span>
                    </div>
                  )}
                  {value.umx !== 0.0 && (
                    <div>
                      <strong>
                        R$ {value.umx.toFixed(2).replace('.', ',')}
                      </strong>
                      <span>em 1x no cartão</span>
                    </div>
                  )}
                  {value.tresx !== 0.0 && (
                    <div>
                      <strong>
                        R$ {value.tresx.toFixed(2).replace('.', ',')}
                      </strong>
                      <span>em 3x no cartão</span>
                    </div>
                  )}
                  {value.seisx !== 0.0 && (
                    <div>
                      <strong>
                        R$ {value.seisx.toFixed(2).replace('.', ',')}
                      </strong>
                      <span>em 6x no cartão</span>
                    </div>
                  )}
                  {value.dezx !== 0.0 && (
                    <div>
                      <strong>
                        R$ {value.dezx.toFixed(2).replace('.', ',')}
                      </strong>
                      <span>em 10x no cartão</span>
                    </div>
                  )}
                </ValoresHolder>
                <button
                  className="finalizar"
                  type="button"
                  ref={buttonRef}
                  onClick={() => {
                    finalizarCompra();
                  }}
                >
                  {loading ? 'Carregando...' : 'Finalizar compra'}
                </button>
              </>
            )}
          </Holder>
        ) : (
          <span>Carrinho vazio</span>
        )}
      </CarrinhoHolder>
    </Layout>
  );
};

export default Carrinho;
