/* eslint-disable @typescript-eslint/ban-types */
import { changeLivroQtd, removeItemFromCart } from 'components/Cart';
import React, { SetStateAction, useCallback, useEffect, useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { ILivroDTO } from 'utils/dto';

import SemCapa from 'assets/livro-indefinido.png';
import api from 'services/api';
import { DisciplinaContainer, ItemCarrinhoContainer, Marcador } from './styles';

interface IProps {
  livro: ILivroDTO;
  serie_id: string;
  item_id: string;
  update: boolean;
  setUpdate: React.Dispatch<SetStateAction<boolean>>;
}

const ItemCarrinho = (props: IProps) => {
  const { livro, serie_id, item_id, update, setUpdate } = props;

  const [qtd, setQtd] = useState<number>(1);
  const [serieName, setSerieName] = useState<string | undefined>();

  const precoTratado = livro.preco.replace('$', 'R$ ').replace('.', ',');
  const precoNumber = Number(livro.preco.replace('$', ''));

  const maiorParcela = livro.desconto10x
    ? { desconto: livro.desconto10x, parcela: 10 }
    : livro.desconto6x
    ? { desconto: livro.desconto6x, parcela: 6 }
    : livro.desconto3x
    ? { desconto: livro.desconto3x, parcela: 3 }
    : livro.desconto1x
    ? { desconto: livro.desconto1x, parcela: 1 }
    : undefined;

  const menorParcela = livro.descontoAvista
    ? { desconto: livro.descontoAvista, parcela: 0 }
    : livro.desconto1x
    ? { desconto: livro.desconto1x, parcela: 1 }
    : livro.desconto3x
    ? { desconto: livro.desconto3x, parcela: 3 }
    : livro.desconto6x
    ? { desconto: livro.desconto6x, parcela: 6 }
    : livro.desconto10x
    ? { desconto: livro.desconto10x, parcela: 10 }
    : { desconto: 0, parcela: 1 };

  const onQtdChange = (action: string) => {
    switch (action) {
      case '-':
        if (qtd !== 1) {
          changeLivroQtd(livro.id, serie_id, qtd - 1);
          setQtd(qtd - 1);
        }
        break;
      case '+':
        changeLivroQtd(livro.id, serie_id, qtd + 1);
        setQtd(qtd + 1);
        break;

      default:
        break;
    }
    setUpdate(!update);
  };

  const getSerieName = useCallback(() => {
    api.get(`/series/serie/${serie_id}`).then(res => {
      const { data } = res;

      setSerieName(data.name);
    });
  }, [serie_id]);

  const removerDoCarrinho = () => {
    removeItemFromCart(item_id);
    setUpdate(!update);
  };

  useEffect(() => {
    getSerieName();
  }, [getSerieName]);

  return (
    <li>
      {livro.renderDisciplina ? (
        <DisciplinaContainer>
          {livro.disciplina} {serieName && `- ${serieName}`}
        </DisciplinaContainer>
      ) : (
        <></>
      )}
      <ItemCarrinhoContainer>
        <Marcador />
        <div className="bookholder">
          <img
            className="capa"
            src={livro.foto ? livro.foto.url : SemCapa}
            alt={livro.titulo}
          />
          <div className="livroInfo">
            <strong className="titulo">{livro.titulo}</strong>
            {livro.edicao && <p className="edicao">{livro.edicao}</p>}
            {livro.editora && (
              <p className="editora">
                Editora:
                <span>{livro.editora}</span>
              </p>
            )}
            {livro.autor && (
              <p className="autor">
                Autor:
                <span>{livro.autor}</span>
              </p>
            )}
          </div>
          <div className="preco">
            <span className="capa">{precoTratado}</span>
            <strong className="vista">
              R${' '}
              {menorParcela &&
                (precoNumber - (precoNumber * menorParcela.desconto) / 100)
                  .toFixed(2)
                  .replace('.', ',')}
              {menorParcela.parcela === 0 ? (
                <p>à vista</p>
              ) : (
                <p>em {menorParcela.parcela}x no cartão</p>
              )}
            </strong>
            <span className="parcelas">
              {maiorParcela && (
                <>
                  ou em até {maiorParcela.parcela}x de R${' '}
                  {maiorParcela.desconto &&
                    (
                      (precoNumber -
                        (precoNumber * maiorParcela.desconto) / 100) /
                      maiorParcela.parcela
                    )
                      .toFixed(2)
                      .replace('.', ',')}
                </>
              )}
            </span>
          </div>
          <div className="qtd">
            <span>{`Quantidade: ${qtd}`}</span>
            <div className="buttons">
              <button onClick={() => onQtdChange('-')} type="button">
                <FaMinus size={15} />
              </button>
              <button onClick={() => onQtdChange('+')} type="button">
                <FaPlus size={15} />
              </button>
            </div>
            <button type="button" onClick={() => removerDoCarrinho()}>
              Remover do carrinho
            </button>
          </div>
        </div>
      </ItemCarrinhoContainer>
    </li>
  );
};

export default ItemCarrinho;
