import styled from 'styled-components';

export const CarrinhoHolder = styled.div`
  width: 70%;
  height: auto;

  margin: 40px 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  h1 {
    color: var(--main-bg);
    font-size: 38px;
    font-weight: 600;
    text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-bottom: 20px;
  }

  @media (max-width: 1366px) {
    width: 85%;
  }

  @media (max-width: 768px) {
    width: 95%;
  }
`;

export const Holder = styled.div`
  width: 80%;
  height: auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  background-color: #fff;
  border-radius: 30px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);

  padding: 50px;

  button {
    margin-top: 60px;
    padding: 15px 30px;
    border-radius: 30px;
  }

  ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    list-style: none;

    width: 100%;
    flex: 1 1;
    li {
      width: 100%;
      &:first-child {
        h3 {
          margin-top: 0px;
          padding-top: 0px;
        }
      }
    }
  }

  button.finalizar {
    text-align: center;
    border: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    background-color: var(--main-color);
    color: var(--main-bg);
    font-weight: 500;
    font-size: 18px;
  }

  @media (max-width: 768px) {
    & {
      width: 100%;
      padding: 30px;
    }
  }
`;

export const ValoresHolder = styled.div`
  margin-top: 15px;

  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  text-align: right;

  strong {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;
    border-bottom: 1px solid #ccc;
    strong {
      font-size: 16px;
      margin-bottom: 0px;
    }
    span {
      margin-left: 5px;
      font-size: 12px;
    }
  }
  div.avista {
    strong {
      font-size: 20px;
    }
  }
`;
